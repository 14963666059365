import React from 'react';
import { http } from 'data-graphql';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Header, FormWrapper, Text, Input, Button } from 'components';

const Wrapper = styled.div`
  .table {
    display: block;
    max-width: 1300px;
    margin: 0.5em auto;
    padding: 15px 20px;
  }

  .table table {
    width: 100%;
    background: white;
    table-spacing: 0;
    border-collapse: collapse;
  }

  .table thead tr {
    background: #6e1406;
    color: white;
    text-align: left;
  }

  .topInfo {
    display: flex;
    flex-wrap: wrap;
    margin: 1em auto;
    max-width: 1300px;
  }

  .topInfo > div {
    width: 50%;
    padding: 1em;
  }

  .topInfo > div > div:first-child {
    margin-bottom: 0.5em;
    font-size: 1.2em;
    font-weight: bold;
  }

  .topInfo .field {
    margin-bottom: 0.5em;
    text-align: left;
  }

  tr td {
    margin: 0;
    border: none;
    text-align: center;
  }

  .table tbody td {
    border-bottom: 1px solid #ccc;
  }

  .table tbody td:last-child {
    text-align: left;
  }

  .table th {
    padding: 0.4em;
  }

  .table tbody td {
    padding: 0.4em;
  }

  .table td:first-child {
    width: 100px;
    text-align: center;
  }

  .table td:nth-child(2) {
    width: calc(50% - 100px);
    padding-left: 1em;
  }

  .table td:last-child {
    width: 50%;
    text-align: left;
  }

  .table input {
    border: 1px solid #aaa;
  }

  input[disabled] {
    background-color: #ffe;
  }

  .radios > div {
    margin-bottom: 0.5em;
  }

  .radios input {
    margin-right: 0.5em;
  }

  .statusInfo {
    color: white;
    background: #6e1406;
    max-width: 300px;
    margin: 0 auto;
    padding: 12px 0;
  }

  .statusInfo .statusDraft {
    font-weight: bold;
    font-size: 18px;
  }

  .statusInfo > :last-child {
    font-weight: normal;
    font-size: 14px;
  }

  .editButtonHolder {
    text-align: center;
    padding: 12px 0;
    margin-bottom: 20px;
  }

  .editButtonHolder button {
    font-size: 18px;
    color: black;
    border-radius: 5px;
    padding: 8px 20px;
  }

  .editButtonHolder > div:last-child {
    color: #6e1406;
    text-align: center;
    max-width: 350px;
    margin: 12px auto;
    font-size: 14px;
  }
`;

const statusLabels = {
  awaiting_processing: 'Awaiting Processing',
  received: 'Received',
  on_hold: 'On Hold',
  complete: 'Complete',
  cancelled: 'Cancelled'
};

export default class OrgEdits extends React.Component {
  constructor() {
    super();
    this.state = {
      show: {},
      edit: {},
      booking: {}
    };
  }

  getBookingEdits = async id => {
    http()
      .get(`/editing/list?booking_id=${id}`)
      .then(response => {
        if (response.success) {
          this.setState({ edits: response.items });
        }
      });
  };

  async componentDidMount() {
    await http()
      .get(`/editing/${this.props.id}`)
      .then(response => {
        if (!response.success) {
          return toast.error('Failed to retrieve edit');
        }
        this.getBookingEdits(response.booking.id);
        // Sort edits by cue number
        response.edit.edits.sort((a, b) =>
          a.cue_number.localeCompare(b.cue_number)
        );
        console.log({ response });
        this.setState(response);
      })
      .catch(error => {
        console.log(error);
        toast.error(error.message);
      });
  }

  render() {
    if (
      !this.state.edit ||
      !this.state.edit.edits ||
      !this.state.booking ||
      !this.state.edits
    )
      return null;
    return (
      <Wrapper>
        {!this.props.hideHeader && (
          <Header>
            <Header.Head bold huge>
              Edit Request #{this.state.editN} for {this.state.show.name}
            </Header.Head>
          </Header>
        )}
        <FormWrapper>
          <div className="topInfo">
            <div className="topInfo1">
              <div>Contact information</div>
              <div>
                <div className="field">
                  Name: <Input value={this.state.edit.name} />
                </div>
                <div className="field">
                  Phone: <Input value={this.state.edit.phone} />
                </div>
                <div className="field">
                  Email: <Input type="email" value={this.state.edit.email} />
                </div>
              </div>
            </div>
            <div className="topInfo2">
              <div>Organization Information</div>
              <div>
                <div className="field">
                  Organization: <Input value={this.state.org.name} disabled />
                </div>
                <div className="field">
                  Opening Date:{' '}
                  <Input
                    value={new Date(
                      this.state.booking.opening
                    ).toLocaleDateString()}
                    disabled
                  />
                </div>
                <div className="field">
                  Account Number:{' '}
                  <Input value={this.state.org.account_number} disabled />
                </div>
              </div>
            </div>
            <div className="topInfo3">
              <div>Turnaround Time</div>
              {this.state.edit.turnaround_time === '5' && (
                <div> 5 Business-day ($60/hour) </div>
              )}

              {this.state.edit.turnaround_time === '2' && (
                <div> 2 Business-day ($120/hour) </div>
              )}
            </div>
            <div className="topInfo4">
              <div className="statusInfo">
                <div>Status</div>
                <div>
                  {this.state.edit.draft ? (
                    <div className="statusDraft">NOT SUBMITTED</div>
                  ) : (
                    statusLabels[this.state.edit.status] ||
                    this.state.edit.status
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            {this.state.edit.editor_notes && (
              <div
                style={{
                  backgroundColor: '#fecb3f',
                  padding: '15px',
                  borderRadius: '8px',
                  margin: '20px auto',
                  maxWidth: '1300px'
                }}
              >
                <div
                  style={{
                    color: '#8B0000',
                    fontWeight: 'bold',
                    marginBottom: '10px'
                  }}
                >
                  EDITOR'S NOTES
                </div>
                <div
                  style={{
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '4px'
                  }}
                >
                  <Text>{this.state.edit.editor_notes}</Text>
                </div>
              </div>
            )}
          </div>

          <Text large>Edits Requested</Text>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Cue #</th>
                  <th>Track Name</th>
                  <th>Edit Request</th>
                </tr>
              </thead>
              <tbody>
                {this.state.edit.edits.map(edit => {
                  return (
                    <tr key={edit.id}>
                      <td>{edit.cue_number}</td>
                      <td style={{ textAlign: 'left' }}>{edit.track_name}</td>
                      <td>
                        {this.state.edits &&
                          this.state.edits
                            .filter(
                              editRequest => editRequest.status === 'complete'
                            ) // Only show completed edit requests
                            .map(editRequest =>
                              editRequest.edits
                                .filter(
                                  localEdit =>
                                    localEdit.track_id === edit.track_id
                                ) // Match current track
                                .map((trackEdit, i) => (
                                  <div
                                    key={`${editRequest.id}-${i}`}
                                    style={{
                                      opacity: 0.7,
                                      marginBottom: '8px',
                                      padding: '4px',
                                      backgroundColor: '#f5f5f5',
                                      borderRadius: '4px'
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: '0.8em',
                                        color: '#666'
                                      }}
                                    >
                                      Completed edit -{' '}
                                      {new Date(
                                        editRequest.created_at
                                      ).toLocaleDateString()}
                                      :
                                    </div>
                                    {trackEdit.edit.map(
                                      (editText, editIndex) => (
                                        <div
                                          key={editIndex}
                                          style={{ marginLeft: '8px' }}
                                        >
                                          • {editText}
                                          {trackEdit.comments &&
                                            trackEdit.comments[editIndex] && (
                                              <div
                                                style={{
                                                  fontSize: '0.8em',
                                                  color: '#666',
                                                  marginLeft: '16px'
                                                }}
                                              >
                                                Comment:{' '}
                                                {trackEdit.comments[editIndex]}
                                              </div>
                                            )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                ))
                            )}
                        <ul style={{ fontWeight: 'bold' }}>
                          {edit.edit
                            .filter(e => e?.trim().length > 0)
                            .map((e, i) => (
                              <li key={i}>{e}</li>
                            ))}
                        </ul>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </FormWrapper>
        {this.state.edit.status === 'awaiting_processing' && (
          <div className="editButtonHolder">
            <div>
              <Button
                onClick={() => {
                  window.location.href = `/org/edits/${this.state.booking.id}/edit/${this.props.id}`;
                }}
              >
                {this.state.edit.draft
                  ? 'Click Here to Edit or Submit your Draft'
                  : 'Click Here to Edit your Submission'}
              </Button>
            </div>
            <div>
              You can add or make changes to your submission until it is marked
              as "Received". After that, any further changes must be submitted
              as an additional edit request.
            </div>
          </div>
        )}
      </Wrapper>
    );
  }
}
