import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { toast } from 'react-toastify';
// import ReactAudioPlayer from 'react-audio-player';
import ReactTooltip from 'react-tooltip';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Icon,
  Text,
  Spacer,
  COLORS,
  FlexBox,
  Button,
  FormWrapper,
  Form,
  Modal
} from 'components';
import {
  http,
  addTrackToManualSetlist,
  removeTrackFromManualSetlist,
  copyAllTracksToBooking,
  getTrackUrl
} from 'data-graphql';
import {
  faExchangeAlt,
  faPlus,
  faEllipsisH
} from '@fortawesome/free-solid-svg-icons';
// import smoothscroll from 'smoothscroll-polyfill';
import { windowSize, isMTI } from 'helpers';

// kick off the polyfill!
// smoothscroll.polyfill();

const ComboTableWrapper = styled.div`
  .track-enter {
    opacity: 0.1;
    height: 0px
  }

  .track-enter.track-enter-active {
    opacity: 1;
    height: auto;
    transition: all 1000ms ease;
  }

  .track-leave {
    opacity: 1;
    height: auto
  }

  .track-leave.track-leave-active {
    opacity: 0.1;
    height: 0px;
    transition: all 1000ms ease;
  }
  .match,
  .mainMatch {
    outline: 2px solid ${COLORS.yellow} ;
  }
  .match.mainList {
    outline: 2px solid ${isMTI ? COLORS.darkBlue : COLORS.dark} ;
  }

  .disabled {
    background: #dedede
    cursor: not-allowed;
    pointer-events: none
  }

  .mainFlex.disabled {
    opacity: 0.6
  }

  .options {
    cursor: pointer
    &:hover {
      background-color: ${COLORS.hoverYellow};
    }
  }
  .react-audio-player {
    height: 30px
    width: 90%
    &:focus, &:active {
      outline: none;
    }
  }
  svg {
    color: ${COLORS.grey}
    &:hover {
      color: black
    }
  }
  .mainFlex div {
    align-self: center
  }
  .resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }
  .resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
`;

const LinkText = styled(Text)`
  padding: 10px;
  background-color: ${isMTI ? '#2096f336' : '#6e14051a'};
  color: ${isMTI ? COLORS.darkBlue : COLORS.dark};
  cursor: pointer;
  &:hover {
    background-color: ${isMTI ? '#2096f336' : '#6e14051a'};
  }
`;

const FlexButton = styled(Button)`
  margin: auto;
  padding: 5px 7px;
  &:hover {
    background-color: ${COLORS.hoverYellow};
  }
`;
//height: ${window.innerHeight - 50}px;
const TrackTableWrapper = styled.div`
  height: calc(100vh - 50px);
  overflow: scroll;
  width: 100%;
  margin: auto;
`;

export default class CurrentShowsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      main_filter: false,
      other_filter: false,
      hideTutorial: true,
      warned: false
    };
  }

  UNSAFE_componentWillMount() {
    this.getTracks();
  }

  toggleTutorial = () => {
    this.setState(prevState => ({ hideTutorial: !prevState.hideTutorial }));
  };

  getTracks = async () => {
    http()
      .get(`/tracks/management-info?bookingId=${this.props.booking.id}`)
      .then(response => {
        if (response.success) {
          const main = response.result.mainTracks.map(track => {
            const cues = track.cue_number.split('-');
            track.cue_start = cues[0] ? cues[0] : track.cue_number;
            track.cue_end = cues[1] ? cues[1] : track.cue_number;
            return track;
          });
          const other = response.result.otherTracks.map(track => {
            const cues = track.cue_number.split('-');
            track.cue_start = cues[0] ? cues[0] : track.cue_number;
            track.cue_end = cues[1] ? cues[1] : track.cue_number;
            return track;
          });

          const alt_setlists = _.uniqBy(other, 'setlist_id').map(track => {
            return {
              name: track.setlist_name,
              type: track.setlist_type,
              id: track.setlist_id
            };
          });

          const setlists = _.uniqBy(main, 'parent_setlist_name').map(track => {
            return {
              name: track.parent_setlist_name,
              type: track.parent_setlist_type,
              id: track.parent_setlist_id,
              key: Math.floor(Math.random() * 100) + track.parent_setlist_name
            };
          });

          const otherTracks =
            this.state.other_filter && this.state.other_filter !== false
              ? other.filter(
                  track => track.setlist_name === this.state.other_filter.name
                )
              : other;

          this.setState({
            main_tracks: main,
            other_tracks: otherTracks,
            initial_main_tracks: main,
            initial_other_tracks: other,
            other_setlists: alt_setlists,
            main_setlists: setlists
          });
        }
      });
  };

  addAllSetlistTracks = async () => {
    const VARS = {
      booking_id: this.props.booking.id,
      setlist_id: this.state.other_filter.id
    };

    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    const RESULT = await copyAllTracksToBooking(VARS, REQ_OBJECT);
    if (RESULT.data.success) {
      this.getTracks();
    } else {
      toast.error(RESULT.data.message);
    }
  };

  addTrackToMain = async track => {
    // const main_matches = this.state.main_tracks.filter(
    //   main_track =>
    //     (main_track.original_track_id !== null &&
    //       main_track.original_track_id === track.original_track_id) ||
    //     main_track.id === track.original_track_id
    // );
    // console.log(main_matches);
    // if (main_matches) {
    //   await Promise.all(
    //     main_matches.map(async match => {
    //       const VARS = {
    //         booking_id: this.props.booking.id,
    //         track_id: match.id
    //       };
    //       const REQ_OBJECT = `{
    //         code
    //         success
    //       }`;

    //       await removeTrackFromManualSetlist(VARS, REQ_OBJECT);
    //     })
    //   );
    // }
    const VARS = {
      booking_id: this.props.booking.id,
      track_id: track.id
    };
    const REQ_OBJECT = `{
      code
      success
    }`;
    await addTrackToManualSetlist(VARS, REQ_OBJECT);
    await this.resetMatches();
    await this.getTracks();
  };

  swapTracks = async () => {
    await Promise.all(
      this.state.main_tracks.map(async track => {
        if (track.match) {
          const VARS = {
            booking_id: this.props.booking.id,
            track_id: track.id
          };
          const REQ_OBJECT = `{
            code
            success
          }`;
          return await removeTrackFromManualSetlist(VARS, REQ_OBJECT);
        } else {
          return false;
        }
      })
    );
    await Promise.all(
      this.state.other_tracks.map(async track => {
        if (track.match) {
          const VARS = {
            booking_id: this.props.booking.id,
            track_id: track.id
          };
          const REQ_OBJECT = `{
            code
            success
          }`;
          return await addTrackToManualSetlist(VARS, REQ_OBJECT);
        } else {
          return false;
        }
      })
    );
    await this.resetMatches();
    await this.getTracks();
  };

  removeTrack = async e => {
    if (!this.state.warned) {
      if (
        !confirm(
          'You are about to remove a track from your show’s active playlist. If you’d like to add this track back to your active playlist, it will be located in the column labeled Other Setlist. Are you sure you’d like to remove this track?'
        )
      )
        return;
      this.setState({ warned: true });
    }

    const VARS = {
      booking_id: this.props.booking.id,
      track_id: e.target.dataset.track
    };
    const REQ_OBJECT = `{
      code
      success
    }`;

    await removeTrackFromManualSetlist(VARS, REQ_OBJECT);

    await this.resetMatches();
    await this.getTracks();
  };

  resetMatches = () => {
    const match = this.state.main_tracks.map(item => {
      item.match === true ? (item.match = false) : (item.match = false);
      item.swapMatch && (item.swapMatch = false);
      item.mainMatch && (item.mainMatch = false);
      return item;
    });
    const match2 = this.state.other_tracks.map(item => {
      item.match === true ? (item.match = false) : (item.match = false);
      item.mainMatch && (item.mainMatch = false);
      return item;
    });

    this.setState({
      main_tracks: match,
      other_tracks: match2,
      track: undefined
    });
  };

  resetMain = e => {
    const currentTrack = this.state.main_tracks.filter(
      track => track.id === e.target.dataset.track
    )[0];
    this.setState({ track: currentTrack || undefined }, () => {
      const match = this.state.main_tracks.map(item => {
        item.match === true ? (item.match = false) : (item.match = false);
        item.swapMatch && (item.swapMatch = false);
        item.mainMatch && (item.mainMatch = false);
        return item;
      });
      const match2 = this.state.other_tracks.map(item => {
        item.match === true ? (item.match = false) : (item.match = false);
        item.swapMatch && (item.swapMatch = false);
        item.mainMatch && (item.mainMatch = false);
        return item;
      });

      this.setState(
        {
          main_tracks: match,
          other_tracks: match2
        },
        this.mainTracksMatch
      );
    });
  };

  mainTracksMatch = e => {
    this.resetMatches();

    const currentTrack = this.state.main_tracks.filter(
      track => track.id === e.target.dataset.track
    )[0];
    if (!currentTrack) return false;
    let matchCheck = this.state.main_tracks.map(item => {
      (item.cue_start >= currentTrack.cue_start &&
        item.cue_start <= currentTrack.cue_end) ||
      (item.cue_end <= currentTrack.cue_end &&
        item.cue_end >= currentTrack.cue_start)
        ? (item.match = true)
        : (item.match = false);
      if (currentTrack === item) {
        item.mainMatch = true;
        item.match = false;
      }
      return item;
    });

    const matchCheck2 = this.state.other_tracks.map(item => {
      (item.cue_start >= currentTrack.cue_start &&
        item.cue_start <= currentTrack.cue_end) ||
      (item.cue_end <= currentTrack.cue_end &&
        item.cue_end >= currentTrack.cue_start)
        ? (item.match = true)
        : (item.match = false);
      if (currentTrack === item) {
        item.mainMatch = true;
        item.match = false;
      }
      return item;
    });

    const match = matchCheck2.filter(item => item.match);
    // eslint-disable-next-line
    for (let newMatch of match) {
      matchCheck = matchCheck.map(item => {
        (item.cue_start >= newMatch.cue_start &&
          item.cue_start <= newMatch.cue_end) ||
        (item.cue_end <= newMatch.cue_end && item.cue_end >= newMatch.cue_start)
          ? (item.match = true)
          : (item.match = false);
        if (currentTrack === item) {
          item.mainMatch = true;
          item.match = false;
        }
        return item;
      });
    }
    this.setState({
      main_tracks: matchCheck,
      other_tracks: matchCheck2
    });
  };

  otherTracksMatch = e => {
    this.resetMatches();
    const currentTrack = this.state.other_tracks.filter(
      track => track.id === e.target.dataset.track
    )[0];

    if (!currentTrack) return false;
    const matchCheck = this.state.main_tracks.map(item => {
      (item.cue_start >= currentTrack.cue_start &&
        item.cue_start <= currentTrack.cue_end) ||
      (item.cue_end <= currentTrack.cue_end &&
        item.cue_end >= currentTrack.cue_start)
        ? (item.swapMatch = true)
        : (item.swapMatch = false);

      item.match = item.swapMatch ? true : false;

      return item;
    });
    const match = matchCheck.filter(item => item.match);

    let matchCheck2 = this.state.other_tracks.map(item => {
      currentTrack === item ||
      (((item.cue_start >= currentTrack.cue_start &&
        item.cue_start <= currentTrack.cue_end) ||
        (item.cue_end <= currentTrack.cue_end &&
          item.cue_end >= currentTrack.cue_start)) &&
        item.setlist_id === currentTrack.setlist_id)
        ? (item.match = true)
        : (item.match = false);

      return item;
    });
    // eslint-disable-next-line
    for (let newMatch of match) {
      matchCheck2 = matchCheck2.map(item => {
        currentTrack === item ||
        (((item.cue_start >= newMatch.cue_start &&
          item.cue_start <= newMatch.cue_end) ||
          (item.cue_end <= newMatch.cue_end &&
            item.cue_end >= newMatch.cue_start)) &&
          item.setlist_id === currentTrack.setlist_id)
          ? (item.match = true)
          : (item.match = false);

        return item;
      });
    }

    this.setState(
      {
        main_tracks: matchCheck,
        other_tracks: matchCheck2
      },
      this.handleScroll(currentTrack.cue_start)
    );
  };

  handleMainFilterChange = mainFilter => {
    const main_tracks = mainFilter
      ? this.state.main_tracks.filter(
          track => track.parent_setlist_name === mainFilter.name
        )
      : this.state.initial_main_tracks;
    this.setState({ main_filter: mainFilter, main_tracks: main_tracks });
  };

  handleOtherFilterChange = otherFilter => {
    const other_tracks = otherFilter
      ? this.state.initial_other_tracks.filter(
          track => track.setlist_name === otherFilter.name
        )
      : this.state.initial_other_tracks;
    this.setState({ other_filter: otherFilter, other_tracks: other_tracks });
  };

  handleScroll = cue => {
    const divToScrollTo = document.getElementById(`${cue}`);
    if (divToScrollTo) {
      divToScrollTo.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  handleCheckboxChange = e => {
    this.setState({ [e.target.name]: e.target.checked });
    if (e.target.name === 'tutorial' && e.target.checked) {
      localStorage.setItem('hideTutorial', 'true');
    }
  };

  closeModal = () => {
    !isMTI &&
      document
        .getElementById('mtpit-tutorial')
        .contentWindow.postMessage(
          '{"event":"command","func":"pauseVideo","args":""}',
          '*'
        );
    isMTI && document.getElementById('mti-tutorial').pause();
    this.setState({ hideTutorial: true });
  };

  preventMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  getTrackUrl = async (item, list) => {
    const audio = new Audio(item.rehearsal_location);
    const playPromise = audio.play();
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          audio.pause();
        })
        .catch(error => {
          console.log(error);
        });
    }

    const VARS = {
      track_id: item.id
    };
    const REQ_OBJECT = `{
      success
      code
      track {
        track_id
        location
      }
      
    }`;

    const RESULT = await getTrackUrl(VARS, REQ_OBJECT);

    if (RESULT.data) {
      if (list === 'main') {
        this.state.main_tracks.map(track => {
          if (
            track.id === RESULT.data.getSignedUrlforTrackPlayback.track.track_id
          ) {
            track.rehearsal_location =
              RESULT.data.getSignedUrlforTrackPlayback.track.location;
            track.play = true;
            return track;
          } else {
            track.play = false;
          }
        });
      } else if (list === 'other') {
        this.state.other_tracks.map(track => {
          if (
            track.id === RESULT.data.getSignedUrlforTrackPlayback.track.track_id
          ) {
            track.rehearsal_location =
              RESULT.data.getSignedUrlforTrackPlayback.track.location;
            track.play = true;
            return track;
          } else {
            track.play = false;
          }
        });
      }
      this.forceUpdate();

      const player = document.getElementById(item.id);

      player.play();
    }
  };

  render() {
    const { booking } = this.props;
    if (!this.state.main_tracks) return null;
    return (
      <>
        {windowSize !== 'desktop' ? (
          <ComboTableWrapper>
            <Text large bold red>
              {booking.show.name}
            </Text>
            <Spacer size="20px" />
            <Text>
              To manage tracks, please visit the organization dashboard on a
              desktop computer.
            </Text>
            <Spacer size="25px" />
            <Text large>Active Playlist</Text>
            <Spacer size="20px" />
            <FlexBox style={{ width: '100%' }} direction="column">
              <FormWrapper
                style={{
                  padding: '8px 5px',
                  margin: '1px auto',
                  border: 'none',
                  boxShadow: 'none',
                  width: '95%',
                  backgroundColor: `${COLORS.darkRed}`,
                  color: 'white',
                  outline: `2px solid ${COLORS.darkRed}`
                }}
              >
                <FlexBox className="mainFlex" justify="space-between">
                  <div style={{ width: '20%' }}>Cue</div>
                  <div style={{ width: '80%', textAlign: 'left' }}>Track</div>
                </FlexBox>
              </FormWrapper>
              <TrackTableWrapper>
                <TransitionGroup className="main-track-list">
                  {this.state.main_tracks
                    .sort((a, b) =>
                      a.cue_number > b.cue_number
                        ? 1
                        : b.cue_number > a.cue_number
                        ? -1
                        : 0
                    )
                    .map(item => (
                      <CSSTransition
                        key={item.id}
                        timeout={500}
                        classNames="track"
                      >
                        <FormWrapper
                          key={item.id}
                          id={item.cue_start}
                          style={{
                            padding: '15px 10px',
                            margin: '2px auto',
                            border: 'none',
                            boxShadow: 'none',
                            width: '95%'
                          }}
                        >
                          <FlexBox className="mainFlex" justify="space-between">
                            <div style={{ width: '20%' }}>
                              <Text style={{ fontSize: '0.85em' }}>
                                {item.cue_number}
                              </Text>
                            </div>
                            <div style={{ width: '80%', textAlign: 'left' }}>
                              <Text style={{ fontSize: '0.85em' }}>
                                {item.track_name.length > 25
                                  ? item.track_name.substr(0, 15) +
                                    '... ' +
                                    item.track_name.substr(
                                      item.track_name.length - 10,
                                      item.track_name.length
                                    )
                                  : item.track_name}
                              </Text>
                            </div>
                          </FlexBox>
                          <Spacer size="10px" />
                          {!item.play ? (
                            <div>
                              <Button
                                onClick={() => {
                                  this.getTrackUrl(item, 'main');
                                }}
                              >
                                ▶
                              </Button>
                            </div>
                          ) : (
                            <audio
                              onContextMenu={this.preventMenu}
                              className="react-audio-player"
                              src={item.rehearsal_location}
                              title={item.track_name}
                              id={item.id}
                              autoPlay={false}
                              controlsList="nodownload"
                              preload="metadata"
                              onPlay={() => {}}
                              onError={() => {
                                console.log('ERROR');
                                this.getTrackUrl(item, 'main');
                              }}
                              controls
                            />
                          )}
                          {/* <audio
                            onContextMenu={this.preventMenu}
                            className="react-audio-player"
                            src={item.rehearsal_location}
                            title={item.track_name}
                            autoPlay={false}
                            controlsList="nodownload"
                            preload="metadata"
                            controls
                          /> */}
                          {/* <ReactAudioPlayer
                            onContextMenu={e => {
                              console.log(e);
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            src={item.rehearsal_location}
                            title={item.track_name}
                            autoPlay={false}
                            controlsList="nodownload"
                            preload="metadata"
                            controls
                          /> */}
                          <Spacer size="10px" />
                        </FormWrapper>
                      </CSSTransition>
                    ))}
                </TransitionGroup>
              </TrackTableWrapper>
            </FlexBox>
          </ComboTableWrapper>
        ) : (
          <>
            <Modal
              open={!this.state.hideTutorial}
              closeOnOverlayClick={true}
              onClose={this.closeModal}
            >
              <div style={{ textAlign: 'center' }}>
                <Text huge red>
                  New Track Management System
                </Text>
                <Spacer size="20px" />
              </div>
              <hr />
              <div style={{ textAlign: 'center' }}>
                <Spacer size="20px" />
                <Text large>
                  We have made significant changes to the playlist management
                  system.{' '}
                </Text>
                <Spacer size="10px" />
                <Text large>
                  Please review our tutorial video to learn how to add and
                  remove tracks from your show&apos;s playlist.
                </Text>
                <Spacer size="30px" />
                <div
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    paddingTop: '56.25%'
                  }}
                >
                  {isMTI && (
                    <video
                      id="mti-tutorial"
                      controls
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        border: '0'
                      }}
                    >
                      <source src="https://mti-player-tutorial-videos.s3-us-west-2.amazonaws.com/MTI+PLAYER+TUTORIALS+-+TRACK+MANAGEMENT.mov" />
                    </video>
                  )}
                  {!isMTI && (
                    <iframe
                      title="MT Pit Tutorial"
                      id="mtpit-tutorial"
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        border: '0'
                      }}
                      autostart="0"
                      src={
                        'https://www.youtube.com/embed/wo8vCB0ihFw?enablejsapi=true'
                      }
                      allowFullScreen
                    ></iframe>
                  )}
                </div>

                <Spacer size="30px" />
                <hr />
                <Spacer size="30px" />
                <Button large straight onClick={this.closeModal}>
                  Close
                </Button>
                {/* <Spacer size="20px" /> */}
                {/* <Form.InputWrapper full>
                  <label>
                    <Form.CheckBox
                      name="tutorial"
                      checked={this.state.tutorial}
                      onChange={this.handleCheckboxChange}
                    />
                    <Text small red>
                      Don&apos;t show me this again.{' '}
                    </Text>
                  </label>
                </Form.InputWrapper> */}
              </div>
            </Modal>

            <ComboTableWrapper>
              <FlexBox justify="space-between">
                <Text large bold>
                  {booking.show.name}
                </Text>
                <Button onClick={this.toggleTutorial}>Show Tutorial</Button>
              </FlexBox>
              <Spacer size="30px" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'top',
                  flexWrap: 'wrap'
                }}
              >
                <div style={{ width: '50%' }}>
                  <div style={{ textAlign: 'center' }}>
                    <div>
                      <Text red huge>
                        Active Tracks
                      </Text>
                    </div>
                    <div>
                      <Text>
                        - Songs in this playlist can be accessed in The MT Pit
                        App -
                      </Text>
                    </div>
                  </div>
                </div>
                <FlexBox
                  direction="row"
                  justify="space-between"
                  style={{ width: '50%' }}
                >
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <div>
                      <Text red huge>
                        Inactive Tracks
                      </Text>
                    </div>
                    <div>
                      <Text>
                        - To access these songs in The MT Pit App, add them to
                        the Active Tracks playlist -
                      </Text>
                    </div>
                  </div>
                  {this.state.other_filter && (
                    <LinkText onClick={this.addAllSetlistTracks}>
                      + Add all {this.state.other_filter.name} tracks to
                      playlist{' '}
                    </LinkText>
                  )}
                </FlexBox>
                <Spacer size="10px" />
                <FlexBox style={{ width: '50%' }} direction="column">
                  <FormWrapper
                    style={{
                      padding: '8px 5px',
                      margin: '1px auto',
                      border: 'none',
                      boxShadow: 'none',
                      width: '95%',
                      backgroundColor: `${COLORS.darkRed}`,
                      color: 'white',
                      outline: `2px solid ${COLORS.darkRed}`
                    }}
                  >
                    <FlexBox className="mainFlex" justify="space-between">
                      <div style={{ width: '15%' }}>Cue</div>
                      <div style={{ width: '45%', textAlign: 'left' }}>
                        Track
                      </div>
                      <div style={{ width: '25%', textAlign: 'left' }}>
                        Setlist
                        {/* <Form.StyledSelect
                    value={this.state.main_filter}
                    onChange={this.handleMainFilterChange}
                    options={this.state.main_setlists}
                    isSearchable={false}
                    isClearable={true}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.name}
                    placeholder="Setlist"
                    styles={Form.CustomStyles}
                  /> */}
                      </div>
                    </FlexBox>
                  </FormWrapper>
                  <TrackTableWrapper>
                    <TransitionGroup className="main-track-list">
                      {this.state.main_tracks
                        .sort((a, b) =>
                          a.cue_number > b.cue_number
                            ? 1
                            : b.cue_number > a.cue_number
                            ? -1
                            : 0
                        )
                        .map(item => (
                          <CSSTransition
                            key={item.id}
                            timeout={500}
                            classNames="track"
                          >
                            <FormWrapper
                              key={item.id}
                              id={item.cue_start}
                              className={
                                item.match
                                  ? 'match mainList'
                                  : item.mainMatch
                                  ? 'mainMatch'
                                  : ''
                              }
                              style={{
                                padding: '15px 10px',
                                margin: '2px auto',
                                border: 'none',
                                boxShadow: 'none',
                                width: '95%'
                              }}
                            >
                              <FlexBox
                                className="mainFlex"
                                justify="space-between"
                              >
                                <div style={{ width: '15%' }}>
                                  {item.cue_number}
                                </div>
                                <div
                                  style={{ width: '45%', textAlign: 'left' }}
                                >
                                  <Text>
                                    {item.track_name.length > 25
                                      ? item.track_name.substr(0, 15) +
                                        '... ' +
                                        item.track_name.substr(
                                          item.track_name.length - 10,
                                          item.track_name.length
                                        )
                                      : item.track_name}
                                  </Text>
                                </div>
                                <div>
                                  <Text>{item.parent_setlist_name}</Text>
                                </div>
                                <div
                                  style={{
                                    width: '10%',
                                    padding: '5px',
                                    backgroundColor: 'white'
                                  }}
                                >
                                  {!item.match && (
                                    <>
                                      <FlexButton
                                        black
                                        data-tip="Remove from main setlist"
                                        data-track={item.id}
                                        style={{ padding: '0px' }}
                                      >
                                        <Icon
                                          data-track={item.id}
                                          onClick={this.removeTrack}
                                          name="close"
                                          style={{
                                            width: '30px',
                                            height: '30px',
                                            padding: '5px 7px'
                                          }}
                                        />
                                      </FlexButton>
                                      <ReactTooltip
                                        place="bottom"
                                        type={isMTI ? 'info' : 'error'}
                                        effect="solid"
                                        multiline={true}
                                      />
                                    </>
                                  )}
                                  {item.match && (
                                    <Text small red>
                                      Track will be replaced
                                    </Text>
                                  )}
                                </div>
                              </FlexBox>
                              <Spacer size="10px" />
                              {!item.play ? (
                                <div>
                                  <Button
                                    onClick={() => {
                                      this.getTrackUrl(item, 'main');
                                    }}
                                  >
                                    ▶
                                  </Button>
                                </div>
                              ) : (
                                <audio
                                  onContextMenu={this.preventMenu}
                                  className="react-audio-player"
                                  src={item.rehearsal_location}
                                  title={item.track_name}
                                  id={item.id}
                                  autoPlay={false}
                                  controlsList="nodownload"
                                  preload="metadata"
                                  onPlay={() => {}}
                                  onError={() => {
                                    console.log('ERROR');
                                    this.getTrackUrl(item, 'main');
                                  }}
                                  controls
                                />
                              )}
                              {/* <audio
                                onContextMenu={this.preventMenu}
                                className="react-audio-player"
                                src={item.rehearsal_location}
                                title={item.track_name}
                                autoPlay={false}
                                controlsList="nodownload"
                                preload="metadata"
                                controls
                              /> */}
                              {/* <ReactAudioPlayer
                                src={item.rehearsal_location}
                                title={item.track_name}
                                autoPlay={false}
                                onContextMenu={e => {
                                  console.log(e);
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                                controlsList="nodownload"
                                preload="metadata"
                                controls
                              /> */}
                              <Spacer size="10px" />
                            </FormWrapper>
                          </CSSTransition>
                        ))}
                    </TransitionGroup>
                  </TrackTableWrapper>
                </FlexBox>
                <FlexBox style={{ width: '50%' }} direction="column">
                  <FormWrapper
                    style={{
                      padding: '8px 5px',
                      margin: '1px auto',
                      border: 'none',
                      boxShadow: 'none',
                      width: '95%',
                      backgroundColor: `${COLORS.darkRed}`,
                      color: 'white',
                      outline: `2px solid ${COLORS.darkRed}`
                    }}
                  >
                    <FlexBox className="mainFlex" justify="space-between">
                      <div style={{ width: '15%' }}>Cue</div>
                      <div style={{ width: '40%', textAlign: 'left' }}>
                        Track
                      </div>
                      <div style={{ width: '30%' }}>
                        <Form.StyledSelect
                          value={this.state.other_filter}
                          onChange={this.handleOtherFilterChange}
                          options={this.state.other_setlists}
                          isSearchable={false}
                          isClearable={true}
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.name}
                          placeholder="Setlist"
                          styles={Form.CustomStyles}
                        />
                      </div>
                    </FlexBox>
                  </FormWrapper>

                  <TrackTableWrapper>
                    <TransitionGroup className="other-track-list">
                      {this.state.other_tracks
                        .sort((a, b) =>
                          a.cue_number > b.cue_number
                            ? 1
                            : b.cue_number > a.cue_number
                            ? -1
                            : 0
                        )
                        .filter(track => !track.is_main)
                        .map(item => {
                          return (
                            <CSSTransition
                              key={item.id}
                              timeout={500}
                              classNames="track"
                            >
                              <FormWrapper
                                key={item.id}
                                style={{
                                  padding: '15px 10px',
                                  margin: '2px auto',
                                  border: 'none',
                                  boxShadow: 'none',
                                  width: '95%'
                                }}
                                className={`${item.match ? 'match' : ''} ${
                                  item.is_main ? 'disabled' : ''
                                }`}
                              >
                                {item.is_main && (
                                  <Text red>In Active Playlist</Text>
                                )}
                                <FlexBox
                                  justify="space-between"
                                  style={{ alignContent: 'center' }}
                                  className={`mainFlex  ${
                                    item.is_main ? 'disabled' : ''
                                  }`}
                                >
                                  <div style={{ width: '15%' }}>
                                  <div
                                    style={{
                                      padding: '5px',
                                      textAlign: 'center',
                                    }}
                                    data-track={item.id}
                                  >
                                    {!item.match && (
                                      <>
                                        <FlexButton
                                          black
                                          data-track={item.id}
                                          style={{ padding: '0px' }}
                                          onClick={this.otherTracksMatch}
                                          data-tip="See options to add Tracks"
                                        >
                                          <FontAwesomeIcon
                                            style={{
                                              color: 'white',
                                              width: '30px',
                                              height: '29px',
                                              padding: '5px 7px'
                                            }}
                                            data-track={item.id}
                                            onClick={this.otherTracksMatch}
                                            icon={faEllipsisH}
                                          />
                                        </FlexButton>
                                        <ReactTooltip
                                          place="bottom"
                                          type={isMTI ? 'info' : 'error'}
                                          effect="solid"
                                          multiline={true}
                                        />
                                      </>
                                    )}
                                    {item.match && (
                                      <FlexBox
                                        align="self-start"
                                        justify="space-between"
                                        style={{
                                          alignContent: 'center',
                                          padding: '10px',
                                          boxShadow:
                                            '1px 1px 2px 0px rgba(0,0,0,0.25)'
                                        }}
                                      >
                                        <div
                                          style={{
                                            cursor: 'pointer',
                                            padding: '0px 5px 5px 0px',
                                            fontSize: '10px'
                                          }}
                                        >
                                          <Icon
                                            onClick={this.resetMatches}
                                            name="close"
                                            style={{
                                              fontWeight: '700',
                                              cursor: 'pointer'
                                            }}
                                            data-tip="Close button panel"
                                          />
                                          <ReactTooltip
                                            place="bottom"
                                            type={isMTI ? 'info' : 'error'}
                                            effect="solid"
                                            multiline={true}
                                          />
                                        </div>
                                        <FontAwesomeIcon
                                          icon={faExchangeAlt}
                                          data-tip="Replace highlighted tracks"
                                          data-track={item.id}
                                          onClick={this.swapTracks}
                                          style={{
                                            alignSelf: 'center',
                                            cursor: 'pointer'
                                          }}
                                        />
                                        <ReactTooltip
                                          place="bottom"
                                          type={isMTI ? 'info' : 'error'}
                                          effect="solid"
                                          multiline={true}
                                        />
                                        <FontAwesomeIcon
                                          icon={faPlus}
                                          data-tip="Add Single Track"
                                          data-track={item.id}
                                          // I know our preference is against this - nature of the icon means occasional click registers on svg rather than icon does not pass data via props well
                                          onClick={() => {
                                            this.addTrackToMain(item);
                                          }}
                                          style={{
                                            alignSelf: 'center',
                                            cursor: 'pointer'
                                          }}
                                        />
                                        <ReactTooltip
                                          place="bottom"
                                          type={isMTI ? 'info' : 'warning'}
                                          effect="solid"
                                          multiline={true}
                                        />
                                      </FlexBox>
                                    )}
                                  </div>
                                    {item.cue_number}
                                  </div>
                                  <div
                                    style={{
                                      textAlign: 'left',
                                      width: '40%'
                                    }}
                                  >
                                    {item.track_name.length > 25
                                      ? item.track_name.substr(0, 15) +
                                        '... ' +
                                        item.track_name.substr(
                                          item.track_name.length - 10,
                                          item.track_name.length
                                        )
                                      : item.track_name}
                                  </div>
                                  <div
                                    style={{
                                      width: item.match ? '20%' : '30%'
                                    }}
                                  >
                                    {item.setlist_name}
                                  </div>
                                </FlexBox>
                                <Spacer size="10px" />
                                {!item.is_main && (
                                  <>
                                    {!item.play ? (
                                      <div>
                                        <Button
                                          onClick={() => {
                                            this.getTrackUrl(item, 'other');
                                          }}
                                        >
                                          ▶
                                        </Button>
                                      </div>
                                    ) : (
                                      <audio
                                        onContextMenu={this.preventMenu}
                                        className="react-audio-player"
                                        src={item.rehearsal_location}
                                        title={item.track_name}
                                        id={item.id}
                                        autoPlay={false}
                                        controlsList="nodownload"
                                        preload="metadata"
                                        onPlay={() => {}}
                                        onError={() => {
                                          console.log('ERROR');
                                          this.getTrackUrl(item, 'other');
                                        }}
                                        controls
                                      />
                                    )}
                                  </>
                                )}
                                {/* <audio
                                  onContextMenu={this.preventMenu}
                                  className="react-audio-player"
                                  src={item.rehearsal_location}
                                  title={item.track_name}
                                  autoPlay={false}
                                  controlsList="nodownload"
                                  preload="metadata"
                                  controls
                                /> */}
                                {/* <ReactAudioPlayer
                                  onContextMenu={e => {
                                    console.log(e);
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                  src={item.rehearsal_location}
                                  title={item.track_name}
                                  autoPlay={false}
                                  controlsList="nodownload"
                                  preload="metadata"
                                  controls
                                /> */}
                                <Spacer size="10px" />
                              </FormWrapper>
                            </CSSTransition>
                          );
                        })}
                    </TransitionGroup>
                  </TrackTableWrapper>
                </FlexBox>
              </div>
            </ComboTableWrapper>
          </>
        )}
      </>
    );
  }
}
